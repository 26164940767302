<template>
  <v-btn
    data-cy="pin"
    icon
    small
    :color="$vuetify.theme.isDark || !isSelected ? undefined : 'white'"
    :disabled="disabled && !isPinned"
    @click.stop="$emit('click', $event)"
  >
    <icon-tooltip
      bottom
      :x-small="xSmall"
      :small="small"
      :icon-name="isPinned ?'i.PinOff' : 'i.Pin'"
      :tooltip-text="isPinned ? $t('t.PinOff'): $t('t.Pin')"
    />
  </v-btn>
</template>

<script>
export default {
  components: {
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      hover: false
    }
  },
  props: {
    disabled: Boolean,
    isPinned: Boolean,
    isSelected: Boolean,
    xSmall: Boolean,
    small: Boolean
  }
}
</script>

<style lang="stylus" scoped></style>
